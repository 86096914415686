(function(window, document){
  window.$ = jQuery.noConflict();
  var $body, $footer;
  
  $(document).ready(function () {

    function refreshColorbox() {
      $('.js-image-lightbox').colorbox({maxWidth: '95%', maxHeight: '95%'});
    }

    $body = $('body');
    $footer = $('.js-footer');

    $('#main-menu').each(function () {
      var $me = $(this);

      $me.find('.menu-item').hover(function () {
        clearTimeout(window._timer_sub_menu);

        // find previous menus
        $(this).prevAll().find('.sub-menu').hide();
        $(this).prevAll().removeClass('hover');
        // find next menus
        $(this).nextAll().find('.sub-menu').hide();
        $(this).nextAll().removeClass('hover');

        if ($(this).find('>.sub-menu').length === 0)
          return;

        $(this).find('>.sub-menu').slideDown();
        $(this).addClass('hover');


      }, function () {

      });

      $me.hover(function () {

      }, function () {
        window._timer_sub_menu = setTimeout(function () {
          $('#main-menu .sub-menu').hide();
          $('#main-menu .menu-item').removeClass('hover');
        }, 500);

      });

      $me.find('.current-menu-item').each(function () {
        $(this).parents('.menu-item').addClass('current-menu-item');
      });
    });

    $('#menu-responsive-button').each(function () {
      $(this).click(function ($e) {
        var $menu = $('#main-menu .menu-container');
        $menu.slideToggle();

        $e.stopPropagation();
        return false;
      });


      $(document).on('click', function () {
        var $menu = $('#main-menu .menu-container');
        if ($('#menu-responsive-button').is(':visible') && $menu.is(':visible')) {
          $menu.hide();
        }
      });
    });

    if ($.fn.flexslider) {
      if ($('.bg-slider').length) {
        var $row_delay = 500;


        $('.bg-slider').addClass('js');
        $('.bg-slider').addClass('loading');
        preload_pictures($('.bg-slider'), function () {
          $('.bg-slider').removeClass('loading');
          $('.cta-blocks').bind('do_effect', function () {
            var $me = $(this);
            var $animate = $(this).data('animate');
            $animate = $animate ? $animate : 'fadeInUp';
            if (Modernizr.cssanimations) {
              $me.stop().visible(true);
              $me.addClass('animated ' + $animate).one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function () {
                $(this).removeClass('animated');
                $(this).removeClass($animate);
              });
            } else {
              $me.stop().visible(true);
            }
          });

          $('.bg-slider .img').bind('active', function () {
            var $slide = $(this);

            $('.cta-blocks').clearQueue().stop(true).visible(false);

            $('.main-block', $slide).delay($row_delay).queue(function () {
              $(this).trigger('do_effect');
            });
          });

          $('.bg-slider .img').bind('deactive', function () {
            var $current_slide = $(this);
            $('.cta-blocks').clearQueue().stop(true);
            $('.cta-blocks', $current_slide).removeClass('animated');
            $('.cta-blocks', $current_slide).visible(false);
          });

          $('.bg-slider').flexslider({
            animation: 'fade',
            animationLoop: true,
            pauseOnAction: false,
            controlNav: false,
            directionNav: false,
            smoothHeight: false,
            video: false,
            slideshowSpeed: 10000,
            animationSpeed: 2000,
            selector: ".img",
            start: function ($slider) {
              var $current_slide = $slider.find('.img:first');
              $('.caption', $current_slide).stop().show();

              $current_slide.trigger('active');

            },
            before: function ($slider) {
              var $current_slide = $slider.find('.flex-active-slide').next();
              if (!$current_slide.length) {
                $current_slide = $slider.find('.img:first');
              }

              $current_slide.trigger('deactive');
            },
            after: function ($slider) {
              var $current_slide = $slider.find('.flex-active-slide');
              $('.caption', $current_slide).stop().show();

              $current_slide.trigger('active');
            }
          });

          $('.bg-slider-nav').click(function () {
            if (window._bg_slider_inaction) {
              clearTimeout(window._bg_slider_inaction);
              window._bg_slider_inaction = false;
            }

            if ($(this).hasClass('previous')) {
              $('.bg-slider').flexslider('prev');
            } else {
              $('.bg-slider').flexslider('next');
            }

            window._bg_slider_inaction = setTimeout(function () {
              $('.bg-slider').flexslider('play');
            }, 10000);

            return false;
          });


        });
      }
    }

    if(!Modernizr.touch && window.skrollr){
      var sr = skrollr.init({
            forceHeight : false
          }
      );

    }

    if ($('.grid-layout').length) {
      var $grid_layout = $('.grid-layout');
      var $grid_items = $('.grid-layout .post');

      preload_pictures($grid_layout, function () {
        var $grid_options = {
          itemSelector: '.post',
          masonry: {
            columnWidth: '.grid-sizer',
            gutter: '.gutter-sizer'
          }
        };

        $grid_layout.grid_layout($grid_options);
      });

      $grid_items.bind('active', function () {
        $(this).addClass('active');
        var $default_class = $(this).data('class');
        var $to_class = $(this).data('to');
        $(this).removeClass($default_class);
        $(this).addClass($to_class);
      });

      $grid_items.bind('deactive', function () {
        $(this).removeClass('active');
        var $default_class = $(this).data('class');
        var $to_class = $(this).data('to');

        $(this).removeClass($to_class);
        $(this).addClass($default_class);

      });

      $grid_items.on('click', '.post-inner, .post-close', function () {
        var $post = $(this).closest('.post');
        if ($post.hasClass('active')) {
          $post.trigger('deactive');
        } else {
          $post.prevAll('.post').add($post.nextAll('.post')).trigger('deactive');
          $post.trigger('active');
        }

        $grid_layout.isotope('layout');

        return false;
      });
    }

    $('body').on('click', '.person-short-info', function($e){
      var $parent = $(this).closest('.person');
      var $person_detail = $parent.find('.person-detail');

      $person_detail.stop().slideDown(200);

      return false;
    }).on('mouseleave', '.person', function(){
      var $parent = $(this).closest('.person');

      var $person_detail = $parent.find('.person-detail');

      $person_detail.stop().hide();

    });

    if($.fn.bxSlider){
      $('.testimonials-scroll-view .bxslider').bxSlider({
        pager : false
      });
    }

    $(window).on('scroll.scrolled', function(){
      var $top = $(this).scrollTop();
      var $offset_top = $('.header-overlay').height();
      if($top <= $offset_top){
        $('body').removeClass('scrolled');
      }else{
        $('body').addClass('scrolled');
      }
    });
    $(window).trigger('scroll.scrolled');

      $('body').on('click', '.tab-list a', function(){
          var $t = $(this);
          var $href = $t.attr('href');
          var $tab = $($href);

          if($tab.length){
              $t.parent().prevAll().removeClass('active');
              $t.parent().nextAll().removeClass('active');
              $t.parent().addClass('active');

              $tab.prevAll().removeClass('active');
              $tab.nextAll().removeClass('active');
              $tab.addClass('active');
          }

          return false;
      });
    
    $('.js-unbranded').on('click', function(){
      $(this).select();
    });

    var $postContent = $('.js-post-content');
    if ($postContent.attr('data-collapse') == 'collapse' && $postContent.length) {
      var $wrapElems = $postContent.find('> :gt(2)');
      if ($wrapElems.length) {
        $wrapElems.wrapAll('<div class="expand js-expand" />');
      }
      $postContent.addClass('is-visible');
    }
    
    $('.js-expand-link').removeClass('is-hidden').on('click', function(){
      $('.js-expand').addClass('is-visible');
      $(this).remove();
    });

    refreshColorbox();

    var $posts = $('.js-posts');

    if ($posts.length) {
      (function(){
        var posts, inProgress = false, postsTemplate = _.template($('.js-post-template').html()), infinite = new Waypoint({
          element: $posts[0],
          offset: 'bottom-in-view',
          handler: function (direction) {
              if (infinite && contentStore.originalView.num_posts < ((contentStore.originalView.vars.posts_per_page || 20) * (contentStore.originalView.vars.paged || 1))) {
                  infinite.destroy();
                  return;
              }
            if (direction == 'down' && !inProgress) {
              inProgress = true;
              var $loader = $('<div class="loader"></div>').insertAfter($posts);
              contentStore.originalView.vars.paged = contentStore.originalView.vars.paged ? ++contentStore.originalView.vars.paged : 2;
              posts = $.ajax({
                url: ajaxurl,
                data: {
                  'action': 'tcs_more_posts',
                  'query': contentStore.originalView.vars
                },
                dataType: 'json',
                method: 'POST',
                cache: false
              }).then(function (data) {
                $loader.remove();
                if (data.status == 'disable') {
                  infinite.destroy();
                }
                else {
                  $posts.append(postsTemplate({posts: data}));
                  $footer.addClass('is-fixed');
                  Waypoint.refreshAll();
                  if (contentStore.originalView.vars.cat == 66) {
                    refreshColorbox(); 
                  }
                  inProgress = false;
                }
              });
            }
          }
        });
      })();
    }
    
    $body.on('click', '.wpcf7-not-valid-tip', function(){
      $(this).prev().focus();
      $(this).fadeOut(200).promise().done(function(){
        $(this).remove();
      });
    });

    $('.js-view-txt').on('click', function(e){
      e.preventDefault();
      $('.js-view-txt-modal').addClass('is-active');
    });

    $('.js-view-html').on('click', function(e){
      e.preventDefault();
      $('.js-view-html-modal').addClass('is-active');
    });

    $('.js-view-txt-modal .js-close, .js-view-html-modal .js-close').on('click', function(){
      $(this).parents('.js-view-txt-modal, .js-view-html-modal').removeClass('is-active');
    });

  });
})(window, document);